

















































































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import AllianzWithdrawalPendingRequestsViewModel
  from '@/vue-app/view-models/allianz-dashboard/withdrawals/allianz-withdrawal-pending-requests-view-model';
// Domain
import {
  TransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/transaction-entity';

@Component({ name: 'AllianzWithdrawalPendingRequests' })
export default class AllianzWithdrawalPendingRequests extends Vue {
  @PropSync('loading', { type: Boolean, required: false, default: false })
  synced_loading!: boolean;

  @PropSync('pendingRequests', { type: Array, required: false, default: () => [] })
  synced_pending_requests!: Array<TransactionEntity>;

  view_model = Vue.observable(
    new AllianzWithdrawalPendingRequestsViewModel(this),
  )

  @Watch('synced_pending_requests')
  handleChangePendingRequests(synced_pending_requests: Array<TransactionEntity>) {
    this.view_model.formatRequests(synced_pending_requests);
  }

  mounted() {
    this.view_model.formatRequests(this.synced_pending_requests);
    this.view_model.initialize();
  }
}

