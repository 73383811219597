import TYPES from '@/types';
import Vue from 'vue';

import { currencyFormat } from '@/vue-app/utils/currency';

// Domain
import {
  TransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/transaction-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type RequestFormatted = {
  id: string;
  request_date: string;
  amount: string;
  status: string;
  accreditation_date: string;
}

export default class AllianzWithdrawalPendingRequestsViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.allianz-dashboard.withdrawals.pending-requests';

  readonly view: Vue;

  pending_requests: RequestFormatted[] = [];

  headers: string[] = [];

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  cancel() {
    this.view.$emit('endProcess');
  }

  nextStep() {
    this.view.$emit('nextStep');
  }

  formatDate(date: string) {
    return date ? this.date_formatter.formatDate(new Date(date), 'DD/MM/YYYY') : '';
  }

  // eslint-disable-next-line consistent-return
  formatStatus = (status_name: string): string => {
    if (status_name === 'Pending') {
      return 'Solicitud pendiente';
    }
    if (status_name === 'Sent') {
      return 'Solicitud enviada';
    }
    return '';
  }

  formatRequests = (requests: Array<TransactionEntity>) => {
    requests.forEach((request) => {
      if (request.transaction_type.name === 'Withdrawal') {
        this.pending_requests.push({
          id: request.id,
          request_date: this.formatDate(request.will_be_processed),
          amount: `${currencyFormat(request.amount)} MXN`,
          status: this.formatStatus(request.transaction_status.name),
          accreditation_date: this.formatDate(request.accreditation_date),
        });
      }
    });
  }

  loadHeaders = () => {
    this.headers = [this.translate('request_date'),
      this.translate('amount'),
      this.translate('status'),
      this.translate('accreditation_date')];
  }

  initialize = async () => {
    this.loadHeaders();
  }
}
